<template>
  <div>
    <v-container class="d-flex justify-space-between align-center py-2">
      <div style="border: solid 1px #999; padding: 10px 20px; width: 100%;">
        請依照下列步驟進行商品上傳<br>
        1. 上傳圖片<br>
        2. 下載Demo檔案<br>
        3. 依照Demo檔案內容填寫商品資訊<br>
        4. 上傳商品檔案
      </div>
    </v-container>
    <v-container class="d-flex flex-column justify-space-between align-center py-2">
      <label for="product-photo-upload" class="custom-file-upload d-flex align-center justify-flex-start">
        <input
          id="product-photo-upload"
          type="file"
          hidden
          multiple
          @change="inputPhoto"
        />
        <v-btn
          color="primary"
          :loading="photoLoading"
          class="mx-2 my-2"
          style="pointer-events: none;"
        >上傳圖片</v-btn>
      </label>
      <v-btn
        color="primary"
        class="mx-2 my-2"
        @click.native="getProductImportSample"
      >
        <span>下載Demo檔案</span>
      </v-btn>
      <label for="product-file-upload" class="custom-file-upload d-flex align-center justify-flex-start">
        <input
          id="product-file-upload"
          type="file"
          hidden
          @change="inputFile"
        />
        <v-btn
          color="primary"
          :loading="fileLoading"
          class="mx-2 my-2"
          style="pointer-events: none;"
        >上傳商品檔案</v-btn>
      </label>
    </v-container>
    <v-container class="px-5">
      <v-sheet rounded class="elevation-1" v-if="imageContents.length !== 0">
        <v-container>
          <v-row class="bg-primary white--text">
            <v-col v-for="(header, index) in imageHeader" :key="index" :cols="header.cols" :class="header.class">
              <span>{{ header.text }}</span>
            </v-col>
          </v-row>
          <v-row v-for="(row, index) in imageContents" class="" :key="index">
            <v-col v-for="(content, index) in row" :key="index" :cols="content.cols" :class="content.class">
              <span>{{ content.text }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-container>
    <v-container class="px-5">
      <v-sheet rounded class="elevation-1" v-if="fileContents.length !== 0">
        <v-container>
          <v-row class="bg-primary white--text">
            <v-col v-for="(header, index) in fileHeader" :key="index" :cols="header.cols" :class="header.class">
              <span>{{ header.text }}</span>
            </v-col>
          </v-row>
          <v-row v-for="(row, index) in fileContents" class="" :key="index">
            <v-col v-for="(content, index) in row" :key="index" :cols="content.cols" :class="content.class">
              <span>{{ content.text }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  data: () => ({
    photoLoading: false,
    fileLoading: false,
    options: [
      {text: '客戶排行', value: 0},
      {text: '商品排行', value: 0},
    ],
    imageContents: [],
    fileContents: []
  }),
  computed: {
    providerId () {
      return this.$store.getters['member/providerId']
    },
    imageHeader () {
      return [
        {text: '圖片檔案名稱', class: "text-center"},
        {text: '上傳狀態', cols: 4, class: "text-center"},
      ]
    },
    fileHeader () {
      return [
        {text: '商品名稱', class: "text-center"},
        {text: '上傳狀態', cols: 4, class: "text-center"},
      ]
    },
  },
  methods: {
    async inputPhoto (file) {
      console.log('inputPhoto:', file, file.target.files)
      if (!file) return
      if (file.length === 0) return
      const fileOverSize = (size) => {
        const Number1MBSIZE = 100 * 1024 * 1024
        return size > Number1MBSIZE
      }
      const acceptType = ['image/png', 'image/jpeg', 'image/jpg']
      const fileList = [...file.target.files].filter((fileItem) => {
        return acceptType.includes(fileItem.type) && !fileOverSize(fileItem.size)
      })
      try {
        this.photoLoading = true
        this.imageContents = []
        for (const file of fileList) {
          const formData = new FormData()
          formData.append('file', file)
          const res = await this.$api.collection.providerApi.putProductImportPhoto(this.providerId, formData)
          this.imageContents.push([
            {text: res.result[0].file, class: "text-center"},
            {text: res.result[0].status, cols: 4},
          ])
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.photoLoading = false
      }
    },
    async inputFile(file) {
      console.log('inputFile:', file)
      if (!file) return
      if (file.length === 0) return
      const fileOverSize = (size) => {
        const Number1MBSIZE = 100 * 1024 * 1024
        return size > Number1MBSIZE
      }
      const acceptType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if(!acceptType.includes(file.target.files[0].type)){
        this.$snotify.warning('檔案類型不支援')
        return
      }
      if (fileOverSize(file.target.files[0].size)) {
        this.$snotify.warning('上傳檔案不得大於100MB')
        return
      }
      try {
        this.fileLoading = true
        let formData = new FormData()
        formData.append('file', file.target.files[0])
        const res = await this.$api.collection.providerApi.putProductImportFile(this.providerId, formData)
        this.fileContents = res.result.map(v => [
          {text: v.product, class: "text-center"},
          {text: v.status, cols: 4},
        ])
      } catch (err) {
        console.log(err)
      } finally {
        this.fileLoading = false
      }
    },
    async getProductImportSample () {
      const response = await this.$api.collection.providerApi.getProductImportSample()
      saveAs(response.url, response.name)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>